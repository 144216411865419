<template>
    <div class="header">
        <div class="container">
            <img class="logoImg" src="../assets/img/nav_logo.png"/>
            <ul class="container-left-ul">
                <li :class="activeName === item.activeUrl ? 'menu-item-active' : ''" v-for="(item, index) in leftMenuList" :key="index">
                    <router-link :to="item.activeUrl" class="">{{ item.titleName }}</router-link>
                </li>
            </ul>
        </div>
    </div>
  </template>
  
  <script>
    export default {
        name: 'navList',
        Props: { 
            activeName: {
                type: String
            },
        },
        data () {
            return {
                dropDownShow: false,
                leftMenuList: [ 
                    { activeName: 'Home', titleName: '远 物', activeUrl: '/' },
                    { activeName: 'Recovery', titleName: '旧机回收', activeUrl: '/recovery' },
                    { activeName: 'Repair', titleName: '手机维修', activeUrl: '/repair' },
                    { activeName: 'About', titleName: '关于我们', activeUrl: '/about' },
                    { activeName: 'HelpCenter', titleName: '帮助中心', activeUrl: '/helpCenter' },
                ],
                activeName: this.$route.path
            }
        },
    }
</script>

<style lang="less" scoped>
    .header{
        width: 100%;
        height: 100px;
        padding: 0 14%;
        color:#fff;
        background-color: #333;
        font-size: 18px;
        box-sizing: border-box;

        .container{
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            .logoImg{
                width: 155px;
                height: 65px;
                margin-right: 121px;
            }
            .container-left-ul{
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex: 1;
                height: 100%;
                padding-right: 20%;

                li{
                    position: relative;
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                }
                .menu-item-active{
                    color: #171717;
                    background-color: #fff;
                    border-radius: 20px;
                }
            }
        }
    }
    
</style>
  