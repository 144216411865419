import axios from "./request.js";

export function home(params){
    return axios({
        url:'/web/v2/Home',
        method:'GET',
        params
    })
}

export function question(params){
    return axios({
        url:'/web/v2/Other/userHelp',
        method:'GET',
        params
    })
}

export function qrcode(params){
    return axios({
        url:'/web/v2/Product/getAlipayQrcode',
        method:'GET',
        params
    })
}

export function productList(params){
    return axios({
        url:'/web/v2/Product',
        method:'GET',
        params
    })
}