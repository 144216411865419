const aesKey = "U2FsdGVkX1+6gBzdAnId7MseO3RJFK+cGbjn3iyo2wg="
import md5 from '@/utils/md5.js'
import Base64s from './base64.js'

export default function(_data) {
	let arr = [],
		arrSty, arrObj = new Object(),
		time = new Date().getTime(),
		md5Ones,
		md5Tow,
		randoms;
	// console.log(_data,'888888888888_data')
	_data['timestamp'] = time;
	// console.log(_data,'_data')
	for (let item in _data) arr.push(item);
	arrSty = arr.sort();
	// console.log(arr,"排序后====");
	for (let item in arr) arrObj[arr[item]] = _data[arr[item]];
	// console.log(arrObj,"处理后11====");
	let str = '';
	Object.keys(arrObj).forEach(key => {
		if(arrObj[key]!==undefined){
			str += `&${key}=${arrObj[key]}`
		}
	});
	let jsobj = str.slice(1);
	// console.log(jsobj,"签名============");
	md5Ones = md5.hex_md5_32(jsobj);
	// console.log(md5Ones,"签名md5============");
	md5Tow = md5.hex_md5_32(aesKey + Base64s(md5Ones + aesKey));
	randoms = Math.floor(Math.random() * 32);
	// console.log(randoms,"随机数==========");

	let onesty, towsty, keys;
	onesty = md5Tow.slice(0, randoms);
	towsty = md5Tow.slice(randoms);
	keys = (randoms.toString(16) + towsty + onesty);
	// console.log(onesty,"第一次加密==========");
	// console.log(towsty,"第二次加密==========");
	let obj = {
		key: keys,
		time: time
	};
	return obj;
}
