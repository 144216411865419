import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as api from './utils/api.js'
import '../src/assets/index.less'

const app = createApp(App)

app.config.globalProperties.$api = api

app.use(router).mount('#app')
app.config.productionTip = false

function is_mobile() {
    var regex_match = /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
  
    var u = navigator.userAgent;
  
    if (null == u) {
        return true;
    }
    var result = regex_match.exec(u);
    if (null == result) {
        return false
    } else {
        return true
    }
  
}
if(is_mobile()){
    window.location.href ='/h5/index.html'
}
