import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index
  },
  {
    path: '/recovery',
    name: 'Recovery',
    component: () => import("../views/recovery.vue"),
  },
  {
    path: '/repair',
    name: 'Repair',
    component: () => import("../views/repair.vue"),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import("../views/about.vue"),
  },
  {
    path: '/helpCenter',
    name: 'HelpCenter',
    component: () => import("../views/helpCenter.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
