<template>
    <NavList />
    <div>
        <img class="index_0" src="http://yuanwu-recovery.oss-cn-shenzhen.aliyuncs.com/uploads/20230814/index_0.png" alt="">
        <div class="containerWrap">
            <div class="flex_ali_jus">
                <div class="item_box">
                    <img class="imgBl" src="../assets/img/index_1.png" alt="">
                    <div>
                        <div>估价透明</div>
                        <div>精准估价</div>
                    </div>
                </div>
                <div class="item_box">
                    <img class="imgBl" src="../assets/img/index_2.png" alt="">
                    <div>
                        <div>快速下单</div>
                        <div>顺丰包邮</div>
                    </div>
                </div>
                <div class="item_box">
                    <img class="imgBl" src="../assets/img/index_3.png" alt="">
                    <div>
                        <div>专业检测</div>
                        <div>保护隐私</div>
                    </div>
                </div>
                <div class="item_box">
                    <img class="imgBl" src="../assets/img/index_4.png" alt="">
                    <div>
                        <div>帮卖高价</div>
                        <div>竞价卖出</div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="title_box">
                    <img src="../assets/img/index_6.png" alt="远物优势">
                </div>
                <img class="index_5" src="http://yuanwu-recovery.oss-cn-shenzhen.aliyuncs.com/uploads/20230814/index_5.png" alt="">
            </div>
            <div class="goodsWrap">
                <div class="title_box">
                    <img src="../assets/img/index_7.png" alt="高价回收">
                </div>
                <div class="goods_box">
                    <div class="goods_item"  v-for="(item,index) in recommend" :key="index"
                        @mouseenter="getQrCode(index,item)" @mouseleave="outQrCode()">
                        <div class="imgBox">
                            <img :src="item.thumbnail_url" alt="">
                        </div>
                        <div class="goods_title">{{ item.name }}</div>
                        <div style="display: flex;">
                            <span>最高回收价</span>
                            <span class="goods_price">￥{{ item.price }}</span>
                        </div>
                        <div :class="current == index ? 'qrcode_wrap show' : 'qrcode_wrap hide'">
                            <!-- <img :src="alipayQrcode" alt=""> -->
                            <img src="../assets/img/index_13.png" alt="">
                            <div>支付宝扫码询价</div>
                            <div>立享专属加价券</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="title_box">
                    <img src="../assets/img/index_8.png" alt="帮卖高价">
                </div>
                <div class="info_box">
                    <div class="info_item info_item_bg_1">
                        <div class="info_tit">省心程度</div>
                        <div>全程帮卖</div>
                        <div>帮卖平台承担售后</div>
                    </div>
                    <div class="info_item info_item_bg_2">
                        <div class="info_tit">卖出时间</div>
                        <div>最快当天</div>
                        <div>95%平均3天内</div>
                    </div>
                    <div class="info_item info_item_bg_3">
                        <div class="info_tit">价格优势</div>
                        <div>价高者得</div>
                        <div>多个市场竞价卖出</div>
                    </div>
                    <div class="info_item info_item_bg_4">
                        <div class="info_tit">拿钱速度</div>
                        <div>质检后帮垫付</div>
                        <div>卖出后拿高价尾款</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <Foot/>
</template>

<script>
    import NavList from '@/components/navList.vue'
    import Foot from '@/components/foot.vue'

    export default {
        name: 'Home',
        data() {
            return{
                recommend: [],
                current: -1,
                isCurrent: false,
                // alipayQrcode: ''
            }
        },
        components: {
            NavList,
            Foot
        },
        created() {
            console.log(process.env)
            this.$api.home().then(res => {
                this.recommend = res.data.recommend
            })
        },
        methods: {
            getQrCode(num,item){
                this.current = num
                // this.$api.qrcode({goodsId:item.id}).then(res => {
                //     this.alipayQrcode = res.data.alipay_qrcode
                // })
            },
            outQrCode(){
                this.current = -1
            }
        },
    }
</script>
<style lang="less" scoped>
    .index_0{
        width: 100%;
        height: 495px;
    }
    .containerWrap{
        .flex_ali_jus{
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding-top: 80px;
        }
        .item_box{
            display: flex;
            align-items: center;
            color: #171717;
            font-size: 20px;

            .imgBl{
                width: 80px;
                height: 80px;
                margin-right: 20px;
            }
        }
        .title_box{
            padding-top: 120px;
            padding-bottom: 50px;

            img{
                display: block;
                width: 112px;
                height: 40px;
             }
        }
        .index_5{
            width: 1200px;
            height: 320px;
            margin: 0 auto;
        }
        .goodsWrap{
            .goods_box{
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .goods_item{
                    position: relative;
                    width: 20%;
                    height: auto;
                    padding: 20px;
                    color: #171717;
                    font-size: 16px;
                    cursor: pointer;
                    box-sizing: border-box;

                    .qrcode_wrap{
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        padding: 20px;
                        background-color: #fff;
                        box-sizing: border-box;
                        font-size: 16px;
                        line-height: 1.8;
                        color: #171717;
                        font-weight: bold;
                        text-align: center;
                        box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
                        border-radius: 8px;
                        z-index: 10;

                        img{
                            width: 200px;
                            height: auto;
                        }
                    }
                    .imgBox{
                        display: flex;
                        align-items: center;
                        width: 200px;
                        height: 200px;
                        margin-bottom: 20px;

                        img{
                            display: block;
                            width: auto;
                            max-width: 200px;
                            height: auto;
                            max-height: 200px;
                            margin: 0 auto;
                        }
                    }
                    
                    .goods_title{
                        margin-bottom: 7px;
                        font-weight: bold;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow:ellipsis;
                    }
                    .goods_price{
                        color: #CC3535;
                        font-size: 18px;
                        margin-left: auto;
                    }
                }
            }
        }
        .info_box{
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;

            .info_item{
                display: block;
                width: 285px;
                height: 200px;
                padding-top: 30px;
                padding-left: 20px;
                color: #171717;
                font-size: 16px;
                line-height: 1.8;
                background-size: 285px 200px;
                box-sizing: border-box;

                &_bg_1{
                    background-image: url(../assets/img/index_9.png);
                }
                &_bg_2{
                    background-image: url(../assets/img/index_10.png);
                }
                &_bg_3{
                    background-image: url(../assets/img/index_11.png);
                }
                &_bg_4{
                    background-image: url(../assets/img/index_12.png);
                }
                .info_tit{
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
            }
        } 
    }
</style>
