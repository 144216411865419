<template>
    <div class="footWArap">
        <div class="foot_box_1">
            <img class="foot_1" src="../assets/img/foot_1.png" alt="">
            <img class="foot_2" src="../assets/img/foot_2.png" alt="">
            <img class="foot_3" src="../assets/img/foot_3.png" alt="">
        </div>
        <div class="box">
            <div class="lianxi_box">
                <div class="box_title">快捷导航</div>
                <div class="toPage_box">
                    <router-link class="toPage" to="/">首页</router-link>
                    <router-link class="toPage" to="/repair">手机维修</router-link>
                    <router-link class="toPage" to="/helpCenter">帮助中心</router-link>
                    <router-link class="toPage" to="/recovery">旧机回收</router-link>
                    <router-link class="toPage" to="/about">关于我们</router-link>
                </div>
            </div>
            <div class="lianxi_box">
                <div class="kefu_box">
                    <div class="foot_4">
                        <img src="../assets/img/foot_4.png" alt="">
                    </div>
                    <div>
                        <div>周一至周日</div> 
                        <div>9:00-18:00</div>
                    </div>
                </div>
                <div>电话: 0755-28018472</div>
                <div>地址: 深圳市龙华区大浪街道龙胜大厦2102B</div>
            </div>
        </div>
        <div class="beian"> 
            <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2022091131号</a>
            | © 深圳市远物网络科技有限公司 版权所有</div>
    </div>
  </template>
  
  <script>
    export default {
        name: 'foot',
    }
</script>

<style lang="less" scoped>
    .footWArap{
        width: 100%;
        height: auto;
        box-sizing: border-box;

        .foot_box_1{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 90px;
            background-color: rgba(61, 61, 61, 0.5);
            padding: 0 14%;
            box-sizing: border-box;

            .foot_1{
                width: 125px;
                height: 51px;
            }
            .foot_2{
                width: 275px;
                height: 53px;
            }
            .foot_3{
                width: 70px;
                height: 70px;
            }
        }

        .box{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 40px 18.75% 50px;
            background-color: #333;
            box-sizing: border-box;

            .lianxi_box{
                display: flex;
                flex-direction: column;
                color: #fff;
                line-height: 2.0;
                font-size: 16px;

                .box_title{
                    font-size: 16px;
                    color: #9B9B9B;
                    font-weight: bold;
                    margin-bottom: 15px;
                }
                .toPage_box{
                    width: 370px;
                }
                .toPage{
                    width: 120px;
                    color: #fff;
                }
                .kefu_box{
                    display: flex;
                    align-items: center;
                    line-height: 1.5;
                    margin-bottom: 10px;

                    .foot_4{
                        width: 48px;
                        height: 48px;
                        margin-right: 16px;
                        
                        img{
                            width: 48px;
                            height: 48px;
                        }
                    }
                } 
            }
        }
        
        .beian{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #333;
            color: #9B9B9B;
            font-size: 14px;
            padding-bottom: 30px;
            a{
                color: #9B9B9B;
            }
        }
    }
</style>
  
