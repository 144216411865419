<template>
  <router-view />
</template>

<script>

export default {
  mounted () {
      this.$router.afterEach((to, from, next) => {
          window.scrollTo(0, 0)
      })
  }
  
}
</script>

<style>
</style>
